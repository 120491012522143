













































































import { PostStore } from '@/stores/post-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { recruitmentApplyController } from '../../dialogs/recruitment-apply-controller'

@Observer
@Component({
  components: {
    'rct-post-action': () => import('@/modules/recruitment/posts/components/rct-post-action.vue'),
  },
})
export default class extends Vue {
  @Prop({}) postStore!: PostStore
  @Prop({ default: false }) enableBlind!: boolean
  @Prop({ default: false }) isRctAdmin
  @Prop({ default: false }) isDetailRctPage
  @Prop({ default: false }) showApplyBtn
  @Prop({ default: false }) isApplied
  @Prop({ default: false }) getAppliedRctLoaded
  recruitmentApplyController = recruitmentApplyController
}
